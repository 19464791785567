//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ } from '~/utils/butterUtils'
import snarkdown from 'snarkdown'

export default {
  props: [
    'url',
    'dict',
     'dictwys',
     'selectedState',
     'voterType',
     'voterTypeDescription'
  ],
  data () {
    return {
      jurisdiction: ''
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    selectedLeo () {
      return this.$store.state.leo.leo ? this.$store.state.leo.leo : {}
    },
    butterLeos () {
      return this.$store.state.butterLeos
    }
  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    md (md) {
      return snarkdown(md)
    }
  }
}
