import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6b4c0850&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VfaScrollUp: require('/opt/build/repo/components/VfaScrollUp.vue').default,VoterCountBallot: require('/opt/build/repo/components/VoterCountBallot.vue').default,VoterFindBallot: require('/opt/build/repo/components/VoterFindBallot.vue').default,VoterRegistered: require('/opt/build/repo/components/VoterRegistered.vue').default,VoterReturnBallot: require('/opt/build/repo/components/VoterReturnBallot.vue').default,VoterState: require('/opt/build/repo/components/VoterState.vue').default})
