//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getNewVfaDate } from '~/utils/butterUtils'

export default {
  props: [ 'deadlines',
           'dict',
           'dictwys',
           'selectedState',
           'stateRules',
           'voterIsRegistered',
           'voterType',
           'voterTypeDescription'
  ],
  data () {
    return {
      returnHelp: '',
      selectedReturnHelp: false
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    today () {
      return getNewVfaDate().getTime()
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
  },
  methods: {
    gotoStateVotingRules () {
      this.$router.push(this.localePath({ name: 'states' }))
    },
    getDictFLJ (dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  }

}
