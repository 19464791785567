//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary,
         getDictFLJ,
         getStateRules,
         getElections,
         getUpcomingElections,
         getNextElection,
         getDeadlineElection,
         getDeadlines,
         getChosenState,
         getNewVfaDate } from '~/utils/butterUtils'
import { MS_PER_DAY,
         getVFAParameters } from '~/utils/VFAParameters'

export default {
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/voter/_stage/index.vue asyncData ')
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    today () {
      return getNewVfaDate().getTime()
    },
    chosenState () {
      return getChosenState(this.$store, this.$route)
    },
    elections () {
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.chosenState.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    upcomingElections () {
      return getUpcomingElections (this.elections)
    },
    deadlineElection () {
      /** 2022-10-24 John Yee
       *  override per VFA team call:
       *  ignore voter's registration status
       *  use the next election because the deadline election is election day
       */
      return getNextElection (this.upcomingElections)
      // return getDeadlineElection (this.upcomingElections, this.voterIsRegistered)
    },
    deadlines () {
      // let d = getDeadlines (this.deadlineElection, this.voterType, this.voterIsRegistered)
      let d = getDeadlines (this.deadlineElection, this.voterType, 'not-used')
      return d
    },
    daysToElection () {
      return this.deadlines.deadlineElection.daysToElection
    },
    daysToRegistrationDeadLine () {
      let d1 = 0
      let d2 = 0
      let dd = -1
      if (this.deadlines.registration) {
        for (let ii=0; ii<this.deadlines.registration.length; ii++){
          d1 = this.deadlines.registration[ii].date
          d2 = (new Date(d1).getTime()-this.today)/MS_PER_DAY
          if (d2>0) {
            dd = d2
          }
        }
      }
      return dd
    },
    daysToBallotRequestDeadLine () {
      let d1 = 0
      let d2 = 0
      let dd = -1
      if (this.deadlines.ballotrequest) {
        for (let ii=0; ii<this.deadlines.ballotrequest.length; ii++){
          d1 = this.deadlines.ballotrequest[ii].date
          d2 = (new Date(d1).getTime()-this.today)/MS_PER_DAY
          if (d2>0) {
            dd = Math.max(dd, d2)
          }
        }
      }
      return dd
    },
    daysToFWABDeadline () {
      // if you can register with FWAB
      // then FWABdeadline = regstration deadline
      // else FWABdeadline = voting deadlines e.g. return by mail postmarked by Nov dd
      if (this.deadlineElection.date) {
        if (this.stateRules.canRegisterWithFwab) {
          return this.deadlineElection.rules.registration[0].date
          //return this.deadlineRegistration
        } else {
          // tricky part here - received by date depends on method returning
          // return by email vs fax vs post
          // for now we'll use first return method
          return this.deadlineElection.rules.ballotreturn[0].date
        }
      } else {
        return 0
      }
    },
    voterTypeDescription () {
      return this.getDictFLJ(`voterType.${this.voterType}`, this.dict)
    },
    voterType () {
      let lss =''
      let lsString = localStorage.getItem('localstoragevotingstate')
      if (lsString) {
        let lsObj = JSON.parse(lsString)
        lss = lsObj.voterType
      }
      return lss || this.$store.state.voterType
    },
    voterIsRegistered () {
      let lss =''
      let lsString = localStorage.getItem('localstoragevotingstate')
      if (lsString) {
        let lsObj = JSON.parse(lsString)
        lss = lsObj.isRegistered
      }
      return lss || this.$store.state.voterIsRegistered ? this.$store.state.voterIsRegistered : "notregistered" 
    },
    stateRules () {
      let scr1 = this.butterStateVotingRules.find(x => x.stateid.toLowerCase().slice(0, 2) === this.chosenState.toLowerCase())
      if (!scr1) {
        return {'iso': this.$route.params.stage}
      }
      return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
    },
    images () {
      return {
        "US": this.butterVFAGraphics.us,
        "eligibility": this.butterVFAGraphics.eligibility,
        "registered": this.butterVFAGraphics.registered,
        "how_do_i": this.butterVFAGraphics.how_do_i,
        "ballot": this.butterVFAGraphics.ballot,
        "count_ballot": this.butterVFAGraphics.count_ballot
      }
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    FWAB_TRIGGER_DEFAULT () {
          return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    dictwys () {
      if (this.butterDictionaryWYSIWYG) {
        return getDictionary(this.butterDictionaryWYSIWYG, this.lang, 'w', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterDictionaryWYSIWYG () {
      return this.$store.state.butterDictionaryWYSIWYG
    }
  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  }
}
